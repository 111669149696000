@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    font-family: 'Lato, sans-serif',
}

body {
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root>.app-container {
    height: 100%;
}

::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

::-webkit-scrollbar-thumb {
    visibility: 'hidden';
    border-radius: 12px;
}