.scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.scrollable-content::-webkit-scrollbar {
    width: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.3);
    border-radius: 20px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0,0,0,.6);
}